const state = {
  isShow: false,
  sign: 1, // 1==>手机号登录 2==>账号登录 3==>邮箱  4==>忘记密码
  module: 'sign' // sign 登录 register 注册 wxsign 微信扫码登录 retrieve 找回密码 reset 重置密码
}
const mutations = {
  setModule (state, value) {
    state.module = value
  },
  setIsShow (state, value) {
    state.isShow = value
  },
  setSign (state, value) {
    state.sign = value
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
