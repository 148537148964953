import Vue from 'vue'
import Vuex from 'vuex'
import header from './header'
import login from './login'
import video from './video'
import { chatUserInfo } from '@/api/login'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    // 用户信息
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}
  },
  getters: {
  },
  mutations: {
    setToken (state, value) {
      state.token = value
      localStorage.setItem('token', value)
    },
    setUserInfo (state, value) {
      state.userInfo = value
    }
  },
  actions: {
    // 退出登录
    logOut ({ commit }) {
      commit('setToken', '')
      commit('setUserInfo', null)
      localStorage.setItem('token', '')
      localStorage.setItem('userInfo', null)
      // 获取当前页面的 URL
      const urlString = window.location.href
      // 判断 URL 中是否有参数
      if (urlString.indexOf('?') !== -1) {
        // 如果有参数，则将参数部分清空
        const baseUrl = urlString.substring(0, urlString.indexOf('?'))
        window.history.replaceState(null, null, baseUrl)
      }
    },
    async updateUserInfo ({ commit }) {
      try {
        const { data } = await chatUserInfo()
        commit('setUserInfo', data)
        return data
      } catch (err) {
        console.log(err.message)
      }
    }
  },
  modules: {
    header,
    login,
    video
  }
})
