import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from '@/utils/utils'
import store from '@/store'

Vue.use(VueRouter)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
// 重写push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const pcRoutes = [
  {
    path: '/',
    redirect: '/web'
  },
  {
    path: '/web',
    component: () => import('@/views/web/web.vue'),
    children: [
      {
        path: '/',
        redirect: '/web/tab_home'
      },
      {
        path: '/web/tab_home',
        component: () => import('@/views/web/tab_home/tab_home.vue'),
        children: [
          {
            path: '/',
            redirect: '/web/tab_home/home'
          },
          {
            path: '/web/tab_home/home',
            name: '首页',
            meta: { keepAlive: false },
            component: () => import('@/views/web/tab_home/home/home.vue')
          },
          {
            path: '/web/tab_home/ai_create',
            name: 'AI绘图',
            meta: { keepAlive: false },
            component: () => import('@/views/web/tab_home/ai_create/ai_create.vue')
          },
          {
            path: '/web/tab_home/ai_video',
            name: '视频创作',
            meta: { keepAlive: false },
            component: () => import('@/views/web/tab_home/ai_video/ai_video.vue')
          }
          // {
          //   path: '/web/tab_home/voucher_center',
          //   name: '充值中心',
          //   meta: { keepAlive: false },
          //   component: () => import('@/views/web/tab_home/voucher_center/voucher_center.vue')
          // }
        ]
      },
      {
        path: '/test',
        component: () => import('@/views/test/test.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/web'
  }
]
const ydRoutes = [
  {
    path: '/',
    redirect: '/mobile'
  },
  {
    path: '/mobile',
    component: () => import('@/mobile/mobile.vue'),
    children: [
      {
        path: '/',
        redirect: '/mobile/tab_home'
      },
      {
        path: '/mobile/tab_home',
        name: '主屏',
        component: () => import('@/mobile/tab_home/tab_home.vue'),
        children: [
          {
            path: '/',
            redirect: '/mobile/tab_home/home'
          },
          {
            path: '/mobile/tab_home/home',
            name: '首页',
            meta: { keepAlive: false },
            component: () => import('@/mobile/tab_home/home/home.vue')
          },
          {
            path: '/mobile/tab_home/ai_create',
            name: 'AI绘图',
            meta: { keepAlive: false },
            component: () => import('@/mobile/tab_home/ai_create/ai_create.vue')
          },
          {
            path: '/mobile/tab_home/ai_video',
            name: 'AI视频',
            meta: { keepAlive: false },
            component: () => import('@/mobile/tab_home/ai_video/ai_video.vue')
          },
          {
            path: '/mobile/tab_home/my',
            name: '我的',
            meta: { keepAlive: false },
            component: () => import('@/mobile/tab_home/my/my.vue')
          }
        ]
      },
      {
        path: '/mobile/login',
        name: '签证',
        component: () => import('@/mobile/login/login.vue'),
        children: [
          {
            path: '/',
            redirect: '/mobile/login/sign_in'
          },
          {
            path: '/mobile/login/sign_in',
            name: '登录',
            component: () => import('@/mobile/login/sign_in/sign_in.vue')
          },
          {
            path: '/mobile/login/register',
            name: '注册',
            component: () => import('@/mobile/login/register/register.vue')
          },
          {
            path: '/mobile/login/retrieve',
            name: '找回密码',
            component: () => import('@/mobile/login/retrieve_password/retrieve_password.vue')
          },
          {
            path: '/mobile/login/reset',
            name: '重置密码',
            component: () => import('@/mobile/login/reset_password/reset_password.vue')
          }
        ]
      },
      {
        path: '/mobile/upgrade',
        name: '升级中心',
        component: () => import('@/mobile/voucher_center/voucher_center.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: '/mobile'
  }
]
const routes = utils.isMobile() ? ydRoutes : pcRoutes
const router = new VueRouter({
  routes
})
// 前置导航守卫
router.beforeEach((to, from, next) => {
  if (to.query.token) {
    store.commit('setToken', to.query.token)
  }
  next()
})
// 后置导航守卫
router.afterEach((to, from) => { })
export default router
