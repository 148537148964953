import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 清除默认样式
import './assets/css/initial.css'
// 导入字体图标库
import './assets/iconfont/iconfont.css'
import ElementUI from 'element-ui'
import 'amfe-flexible'
import 'element-ui/lib/theme-chalk/index.css'
// 注册瀑布流组件
import water from 'vue-waterfall2'
// 全局自定义组件
import './utils/components'
import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/css/vant.less'
import '@/assets/css/element.less'
// import { isMobile } from './utils/tool'

// debugger
// if (!isMobile) {
//   debugger
// }

Vue.use(water)
Vue.use(Vant)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
