import request from '@/utils/request'

// 登录
export function chatLogin (data) {
  return request({
    url: '/chat/login',
    method: 'POST',
    data
  })
}
// 获取用户信息
export function chatUserInfo (params) {
  return request({
    url: '/chat/user/info',
    method: 'GET',
    params
  })
}

// 获取图形码
export function chathelpCaptcha (params) {
  return request({
    url: '/chat/help/captcha',
    method: 'GET',
    params
  })
}
// 注册
export function chatRegister (data) {
  return request({
    url: '/chat/register',
    method: 'POST',
    data
  })
}

// 获取账号名称
export function chatGetUserName (params) {
  return request({
    url: '/chat/get-user-name',
    method: 'get',
    params
  })
}

// 重置密码
export function chatResetPass (data) {
  return request({
    url: '/chat/reset-pass',
    method: 'POST',
    data
  })
}

// 获取验证码
export function chatCaptchaCode (data) {
  return request({
    url: '/chat/captcha-code',
    method: 'POST',
    data
  })
}

// 微信扫码登录
export function chatWxScanLogin (data) {
  return request({
    url: '/chat/wx-scan-login',
    method: 'post',
    data
  })
}

// 发送短信验证码 （带阿里云验证）
export function chatSendSms (data) {
  return request({
    url: '/chat/send-sms',
    method: 'POST',
    data
  })
}

// 手机号注册
export function chatMobileRegister (data) {
  return request({
    url: '/chat/mobile-register',
    method: 'POST',
    data
  })
}

// 获取微信登录二维码
export function chatWXLogin (params) {
  return request({
    url: '/chat/wechat/login-qrcode',
    method: 'GET',
    params
  })
}
// 扫码关注公众号
export function chatWxOfficalFollow (data) {
  return request({
    url: '/chat/wx-offical-qrcode-login',
    method: 'POST',
    data
  })
}
