<template>
  <div class="yd-form hl-form">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'YDForm',
  data () {
    return {
      testVerify: false,
      verifyCode: [],
      time: null
    }
  },
  provide () {
    return {
      hlForm: this
    }
  },
  props: {
    model: {
      type: Object
    },
    rule: {
      type: Object
    }
  },
  methods: {
    // 开启验证
    validate (fn) {
      this.testVerify = !this.testVerify
      this.verifyCode = []
      this.time = setInterval(() => {
        if (this.verifyCode.length > 0) {
          clearInterval(this.time)
          const bol = this.verifyCode.every(item => item === true)
          fn(bol)
        }
      }, 100)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./form.less');
</style>
