export default {
  // 用户名缩略
  formatName (val) {
    const str = val + ''
    const len = str.length
    let newStr = ''
    if (len <= 2) {
      newStr = str.substring(0, 1) + '*'.repeat(len - 0)
    } else if (len > 2 && len <= 3) {
      newStr = str.substring(0, 1) + '*'.repeat(len - 0) + str.substring(len - 1, len)
    } else if (len > 3 && len <= 4) {
      newStr = str.substring(0, 1) + '*'.repeat(len - 2) + str.substring(len - 1, len)
    } else if (len > 4 && len <= 8) {
      newStr = str.substring(0, 2) + '*'.repeat(len - 3) + str.substring(len - 2, len)
    } else if (len > 8) {
      newStr = str.substring(0, 3) + '*'.repeat(len - 6) + str.substring(len - 3, len)
    }
    return newStr
  },
  // 时间转换
  timeConvert (val) {
    const now = new Date(val) // 获取当前时间
    const year = now.getFullYear() // 获取年份
    const month = now.getMonth() + 1 // 获取月份，需要加 1
    const day = now.getDate() // 获取日期
    const hour = now.getHours() // 获取小时
    const minute = now.getMinutes() // 获取分钟
    const second = now.getSeconds() // 获取秒钟
    return {
      year,
      month,
      day,
      hour,
      minute,
      second
    }
  },
  // 监测用户 设备是否未移动设备
  isMobile  () {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
    )
  }
}
