const state = {
  // 背景是否透明
  isTransparent: true,
  // 当前主题色
  themeType: 'dark', // dark bright
  // 登录组件的显示与隐藏
  isLogin: false,
  // 账号设置组件的显示与隐藏
  isAccount: false,
  // 账号设置当前所属的模块
  accountMoudel: 'account', // account 账号设置 avatar 头像设置 details 账号信息 log_off 注销密码确认 change 修改密码
  // 是否显示购买
  isShowBuy: false
}
const mutations = {
  setIsTransparent (state, value) {
    state.isTransparent = value
  },
  setThemeType (state, value) {
    state.themeType = value
  },
  setIsLogin (state, value) {
    state.isLogin = value
  },
  setIsAccount (state, value) {
    state.isAccount = value
  },
  setAccountMoudel (state, value) {
    state.accountMoudel = value
  },
  // 通用的mutations修改state
  updateState (state, payload) {
    state[payload.key] = payload.value
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
