const state = {
  // 视频生成记录
  videoRecord: JSON.parse(localStorage.getItem('videoRecord')) || [],
  // 活跃视频id null:初始
  videoId: null
}
const getters = {
  videoIndex ({ videoRecord, videoId }) {
    return videoId === null ? -1 : videoRecord.findIndex(i => i.id === videoId)
  }
}
const mutations = {
  // 通用的mutations修改state
  updateState (state, payload) {
    state[payload.key] = payload.value
  },
  // 添加记录
  addRecord (state, obj) {
    state.videoRecord = [obj].concat(state.videoRecord)
    localStorage.setItem('videoRecord', JSON.stringify(state.videoRecord))
  },
  // 更新记录
  updateRecord (state, { index, value }) {
    state.videoRecord[index] = value
    localStorage.setItem('videoRecord', JSON.stringify(state.videoRecord))
  },
  // 修改记录某项值
  updateRecordItem (state, { id, key, value }) {
    const index = state.videoRecord.findIndex(i => i.id === id)
    if (index !== -1) {
      state.videoRecord[index][key] = value
    }
    // localStorage.setItem('videoRecord', JSON.stringify(state.videoRecord))
  },
  // 删除记录
  deleteRecordItem (state, { id, index }) {
    if (!index) {
      index = state.videoRecord.findIndex(i => i.id === id)
    }
    state.videoRecord.splice(index, 1)
    localStorage.setItem('videoRecord', JSON.stringify(state.videoRecord))
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
