// 导入实例
import Vue from 'vue'
// 注册组件
import hlInput from '@/mobileComponents/input/input.vue'
import hlForm from '@/mobileComponents/form/form.vue'
import hlFormItem from '@/mobileComponents/form_item/form_item.vue'

Vue.component('hl-input', hlInput)
Vue.component('hl-form', hlForm)
Vue.component('hl-form-item', hlFormItem)
